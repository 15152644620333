@import "colors.scss";

.floating-button-container {
    position: fixed;
    right: 1.25rem;
    bottom: 18rem;
    z-index: 10;
    width: 22rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .floating-button {
        width: 2.8125rem;
        height: 2.8125rem;
        text-align: center;

        border-radius: 50%;


        transform: scale(1);
	    animation: pulse 2s infinite;

        img {
            display: block;
            width: 100%;
            padding: .3rem;
        }
    }
    .toast {
        padding: 0.5rem 0.875rem;
        border-radius: 0.625rem;
        font-weight: bold;
        font-size: .7rem;
        text-transform: uppercase;
        margin-right: 1.875rem;
        margin-bottom: 0.3125rem;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 0.375rem rgba(0,0,0,0.3);
        margin-left: auto;
        max-width: calc(100% - 5.4rem);
        position: relative;
    }

    &.floating-bg-danger {
        .toast {
            color: #FFFFFF;
            background-color: $color-red-darker;
        }
        .floating-button {
            background-color: $color-red-darker;
        }
    }

    &.floating-bg-warning {
        .toast {
            color: #444;
            background-color: $color-orange;
        }
        .floating-button {
            background-color: $color-orange;
        }
    }

    &.floating-bg-success {
        .toast {
            color: #FFFFFF;
            background-color: $color-success;
        }
        .floating-button {
            background-color: $color-success;
        }
    }

    &.floating-bg-primary {
        .toast {
            color: #FFFFFF;
            background-color: $color-blue;
        }
        .floating-button {
            background-color: $color-red-darker;
        }
    }
}


@media screen and (max-width: 500px) {
    .floating-button-container {
        bottom: 2rem;

        .toast {
            max-width: calc(100% - 12.5rem);
            position: inherit;
        }
    }
}

@keyframes pulse {
    0% {
      transform: scale(0.90);
      box-shadow: 0 0 0 0 rgb(226 14 14 / 70%);
    }
    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }